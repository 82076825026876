<template>
  <div class="component-learn">
    <div class="content">
      <img class="image" src="/assets/img/image.learn.svg" alt="Chracter for learn page" />
      <div class="start part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "시작하기" : "Getting started" }}</div>
        <template v-if="store.state.site.lang === 'ko'">
          <div class="alert alert-warning">{{ env.VUE_APP_ALERT_KO }}</div>
          <div>다음의 순서에 따라 진행하시면 페이지를 생성하고 추출하실 수 있습니다.</div>
          <ul>
            <li>- 우측 상단에 있는 콘솔 버튼을 클릭합니다.</li>
            <li>- 명령창에 join 입력 후 엔터를 눌러 가입합니다.</li>
            <li>- 명령창에 login 입력 후 엔터를 눌러 로그인합니다.</li>
            <li>- 명령창에 create startup 입력 후 엔터를 눌러 페이지를 생성합니다.</li>
            <li>- 명령창에 open startup 입력 후 엔터를 눌러 생성된 페이지를 오픈합니다.</li>
            <li>- 명령창에 @root append header 100 입력 후 엔터를 눌러 컴포넌트를 추가합니다.</li>
            <li>- 명령창에 @root append slider 100 입력 후 엔터를 눌러 컴포넌트를 추가합니다.</li>
            <li>- 명령창에 @root append footer 100 입력 후 엔터를 눌러 컴포넌트를 추가합니다.</li>
            <li>- 명령창에 export 입력 후 엔터를 눌러 소스를 다운로드합니다.</li>
          </ul>
        </template>
        <template v-else>
          <div class="alert alert-warning">{{ env.VUE_APP_ALERT_EN }}</div>
          <div>You can create and export a page by following the steps below.</div>
          <ul>
            <li>- Click the console button in the upper right corner.</li>
            <li>- Enter join in the command console and press Enter to join.</li>
            <li>- After entering login in the command console, press Enter to log in.</li>
            <li>- After typing create startup in the command console, press Enter to create the page.</li>
            <li>- After typing open startup in the command console, press Enter to open the created page.</li>
            <li>- In the command console, type @root append header 100 and press Enter to add the component.</li>
            <li>- In the command console, type @root append slider 100 and press Enter to add a component.</li>
            <li>- In the command console, type @root append footer 100 and press Enter to add the component.</li>
            <li>- After typing export in the command console, press Enter to download the source.</li>
          </ul>
        </template>
      </div>
      <div class="manual part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "동영상 매뉴얼" : "Video manuals" }}</div>
        <div class="inner">
          <div class="on">
            <div class="ratio-video">
              <iframe
                width="100%"
                height="100%"
                title="Video Player"
                :src="`https://www.youtube.com/embed/${state.video}?list=PLtht1_et-35B58-NHHItPWOuyH6c3YhN0`"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                ref="videoRef"
              ></iframe>
            </div>
          </div>
          <div class="list">
            <div class="inner">
              <ul>
                <li v-for="(v, idx) in videos" :key="idx" :class="{ active: v === state.video }" @click="state.video = v">
                  <img :src="`https://img.youtube.com/vi/${v}/mqdefault.jpg`" alt="Video thumbnail" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="commands part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "콘솔 명령어" : "Console commands" }}</div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>{{ store.state.site.lang === "ko" ? "명령어" : "Command" }}</th>
                <th>{{ store.state.site.lang === "ko" ? "설명" : "Desc" }}</th>
                <th>{{ store.state.site.lang === "ko" ? "인자 값" : "Args" }}</th>
                <th>{{ store.state.site.lang === "ko" ? "예제" : "Examples" }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, idx) in commands.console" :key="idx">
                <td>{{ c.cmd }}</td>
                <td>{{ c.desc[store.state.site.lang] }}</td>
                <td>{{ c.args.join(" ") }}</td>
                <td>
                  <div v-for="(e, idx) in c.examples" :key="idx">{{ e }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="part">
        <div class="sm-title">{{ store.state.site.lang === "ko" ? "노드 명령어" : "Node commands" }}</div>
        <div class="table-responsive">
          <table class="table no-margin-bottom">
            <thead>
              <tr>
                <th>{{ store.state.site.lang === "ko" ? "명령어" : "Command" }}</th>
                <th>{{ store.state.site.lang === "ko" ? "설명" : "Desc" }}</th>
                <th>{{ store.state.site.lang === "ko" ? "인자 값" : "Args" }}</th>
                <th>{{ store.state.site.lang === "ko" ? "예제" : "Examples" }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, idx) in commands.node" :key="idx">
                <td>{{ c.cmd }}</td>
                <td>{{ c.desc[store.state.site.lang] }}</td>
                <td>{{ c.args.join(" ") }}</td>
                <td>
                  <div v-for="(e, idx) in c.examples" :key="idx">{{ e }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import defines from "../defines.json";

export default {
  props: {
    callback: Function,
    load: Function,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      video: "",
    });

    const lnbRef = ref(null);
    const nav = defines.navs.find((n) => n.name === "learn");
    const definedCommands = defines.commands;
    const videos = defines.help.videos;

    const commands = {
      console: [],
      node: [],
    };

    for (let i1 in definedCommands) {
      for (let i2 in definedCommands[i1]) {
        for (let c of definedCommands[i1][i2]) {
          commands[i1].push(c);
        }
      }
    }

    for (let i in commands) {
      commands[i].sort((a, b) => {
        if (a.cmd < b.cmd) {
          return -1;
        } else if (a.cmd > b.cmd) {
          return 1;
        }

        return 0;
      });
    }

    state.video = videos[0];

    onMounted(() => {
      if (typeof props.load === "function") {
        props.load(lnbRef.value);
      }
    });

    return { store, state, lnbRef, nav, commands, videos, env: process.env };
  },
};
</script>
<style lang="scss" scoped>
.component-learn {
  .parallax:before {
    background-image: url(/assets/img/post-impressionist-1428129_1280.jpg);
  }

  .part {
    &.start {
      .alert {
        margin: 20px 0 15px 0;
        display: none;
      }

      ul {
        margin: 15px 0 0 0;
        padding: 0;
        list-style: none;

        li {
          font-size: 15px;
          margin-bottom: 7.5px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.manual {
      > .inner {
        margin: 0 -15px;
        position: relative;

        > div {
          padding: 0 15px;

          &.on {
            padding-right: 0;
            width: 75%;
          }

          &.list {
            width: 25%;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;

            .inner {
              position: relative;
              background: #eee;
              height: 100%;

              ul {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: auto;
                padding: 15px;
                margin: 0;
                list-style: none;
                border: 1px solid #ccc;

                > li {
                  cursor: pointer;
                  margin-bottom: 15px;

                  &.active {
                    opacity: 0.5;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  table {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    th,
    td {
      font-size: 14px;
    }

    th {
      border-top: 0;
      border-bottom-width: 1px;
      background: #eee;
      font-weight: normal;
    }

    td {
      vertical-align: middle;
      white-space: pre-line;
    }
  }

  &[size="xs"],
  &[size="xxs"] {
    .part.manual > .inner {
      margin: 0;

      > div {
        padding: 0;

        &.on {
          width: 100%;
          margin-bottom: 15px;
        }

        &.list {
          width: 100%;
          position: relative;

          .inner ul {
            position: relative;
            white-space: nowrap;
            overflow: auto;

            li {
              display: inline-block;
              width: 150px;
              margin: 0 10px 0 0;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .part.start .alert {
      display: block;
    }
  }

  @media (max-width: 767px) {
    table td {
      white-space: nowrap;
    }
  }
}
</style>
